<template>
    <div class="home">
      <v-layout row wrap class="d-flex justify-center">
        <v-flex xs12 md12 lg12 class="mb-5">
          <h1 class="f-alfa primary--text"><router-link to="/">Pro Track</router-link> / Roles</h1>
        </v-flex>
        <v-flex xs12>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left uppercase grey--text">#</th>
                  <th class="text-left uppercase grey--text">Role</th>
                  <th class="text-left uppercase grey--text">Description</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td class="primary--text fw-bold uppercase">Super Admin</td>
                  <td class="grey--text text--lighten-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et enim quas voluptate culpa dignissimos! Sit eligendi deleniti, expedita molestias, fuga non fugit aut unde ab reiciendis laborum adipisci officia labore.</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td class="primary--text fw-bold uppercase">Admin</td>
                  <td class="grey--text text--lighten-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et enim quas voluptate culpa dignissimos! Sit eligendi deleniti, expedita molestias, fuga non fugit aut unde ab reiciendis laborum adipisci officia labore.</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td class="primary--text fw-bold uppercase">Department Lead</td>
                  <td class="grey--text text--lighten-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et enim quas voluptate culpa dignissimos! Sit eligendi deleniti, expedita molestias, fuga non fugit aut unde ab reiciendis laborum adipisci officia labore.</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td class="primary--text fw-bold uppercase">Team Member</td>
                  <td class="grey--text text--lighten-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et enim quas voluptate culpa dignissimos! Sit eligendi deleniti, expedita molestias, fuga non fugit aut unde ab reiciendis laborum adipisci officia labore.</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import Axios from "axios";
  import { BASE_URL } from "@/config";
  export default {
    data() {
      return {
        roles: [],
      };
    },
    mounted() {
      this.fetchRoles();
    },
   
    methods: {
      async fetchRoles() {
        let url = BASE_URL + "/role";
        let { data } = await Axios.get(url);
        this.roles = data;
      },
      addRole(){
        this.$router.push('/add-role')
      }
    },
  };
  </script>
  
  
  